//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import wx from 'weixin-js-sdk';
export default {
	data: ()=> ({
		imgUrls:[
			{
                idx:5,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-2-8/shopDemo5.png",
            },
            {
                idx:2,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2020-12-2/营销海报2.png",
            },
            {
                idx:3,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2020-12-2/营销海报3.png",
			},
			{
                idx:1,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2020-12-2/营销海报1.png",
            },
            {
                idx:4,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2020-12-2/营销海报4.png",
            },
		],
		weAppEnv: false,
	}),
	metaInfo () {
      return {
        title: '选择海报模板',
      }
    },
	mounted() {
		let _this = this
		wx.miniProgram.getEnv(function(res) { 
			_this.weAppEnv = res.miniprogram
		})
	},
	methods: {
		choose(item) {
			let {shop_id = null} = this.$route.params
			if(shop_id) {
				let {idx} = item,
					src = 'https://fabric.yctop.com/#/shop/' + shop_id + '/' + idx + '?t=' + new Date().getTime()
				if(this.weAppEnv) {
					wx.miniProgram.navigateTo({
						url: '/shop/painter/index?src=' + encodeURIComponent(src),
					})
				} else {
					this.$toast('当前不是小程序环境:' + src)
				}

			}
		}
	}
}
